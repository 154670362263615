import React, { useEffect, useState } from 'react'
import Layout from '../components/Layout'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
import axios from 'axios';
function RolePermissions() {
    const [roles, setRoles] = useState([]);
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const [permissions, setPermissions] = useState([]);

    const api = process.env.REACT_APP_API_URL;

    const notyf = new Notyf({
        duration: 1000,
        position: {
            x: 'right',
            y: 'top',
        },
        types: [
            {
                type: 'warning',
                background: 'orange',
                icon: {
                    className: 'material-icons',
                    tagName: 'i',
                    text: 'warning'
                }
            },
            {
                type: 'error',
                background: 'indianred',
                duration: 2000,
                dismissible: true
            },
            {
                type: 'success',
                background: '#7dd3e8',
                duration: 2000,
                dismissible: true
            }
        ]
    });

    const handleChangePermissions = (event) => {
        setSelectedPermissions(event.target.value);
    };

    const handleChange = (event) => {
        const { value } = event.target;
        setIdRole(value);
    };

    const submitRolePermissions = () => {
        if(idRole==0 ||selectedPermissions.length==0){
            notyf.open({
                type: 'error',
                message: 'Data are missing'
            });
        }else{
            axios.post(api + 'roles/role-permissions/' + idRole, {
                permissions:selectedPermissions
              },{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    Accept: "application/json",
                },
            })
              .then(function (res) {
                if(res.data.check==true){
                    notyf.open({
                        type: 'success',
                        message: 'Updated successfully'
                    });
                    setSelectedPermissions(res.data.data);
                }else if(res.data.check==false){
                    if(res.data.msg){
                        notyf.open({
                            type: 'error',
                            message: res.data.msg
                        });
                    }
                }
            })
        }
    }

    const [idRole, setIdRole] = React.useState(0);

    useEffect(() => {
        axios.get(api + 'roles',{
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                Accept: "application/json",
            },
        }).then((res) => {
            setRoles(res.data);
        })
        axios.get(api + 'permissions',{
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                Accept: "application/json",
            },
        }).then((res) => {
            setPermissions(res.data);
        })
        if (idRole != 0) {
            axios.get(api + 'roles/role-permissions/' + idRole,{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    Accept: "application/json",
                },
            }).then((res) => {
                setSelectedPermissions(res.data);
            })
        }

        // console.log(process.env.REACT_APP_API_URL);
    }, [idRole])
    return (
        <Layout>
            <>
                <div className="row">
                    <div className="col-md-3">
                        <FormControl sx={{ m: 1, minWidth: 300 }}>
                            <InputLabel id="demo-simple-select-autowidth-label">Roles</InputLabel>
                            <Select
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                value={idRole}
                                onChange={handleChange}
                                autoWidth
                                label="Role"
                            >
                                {roles && roles.length > 0 && roles.map((item, index) => (
                                    <MenuItem className='w-100' key={index} value={item.id}>{item.name}</MenuItem>

                                ))}

                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-5">
                        <FormControl sx={{ m: 1, minWidth: 300 }}>
                            <InputLabel id="demo-simple-select-autowidth-label">Permissions</InputLabel>
                            <Select
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                value={selectedPermissions}
                                multiple
                                onChange={handleChangePermissions}
                                autoWidth
                                label="Role"
                            >
                                {permissions && permissions.length > 0 && permissions.map((item, index) => (
                                    <MenuItem className='w-100' key={index} value={item.id}>{item.name}</MenuItem>

                                ))}

                            </Select>
                        </FormControl>
                        <button className='btn btn-primary ms-3 mt-3' onClick={(e) => submitRolePermissions()}>Submit</button>
                    </div>
                </div>
            </>
        </Layout>

    )
}

export default RolePermissions