/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Layout from '../components/Layout'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Box, Switch, Typography } from "@mui/material";
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
function Brands() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [brands, setbrands] = useState([]);
    const [brand, setbrand] = useState('');
    const handleShow = () => setShow(true);
    const api = process.env.REACT_APP_API_URL;
    const resetCreate = () => {
        setbrand('');
        handleClose()
    }
    const notyf = new Notyf({
        duration: 1000,
        position: {
            x: 'right',
            y: 'top',
        },
        types: [
            {
                type: 'warning',
                background: 'orange',
                icon: {
                    className: 'material-icons',
                    tagName: 'i',
                    text: 'warning'
                }
            },
            {
                type: 'error',
                background: 'indianred',
                duration: 2000,
                dismissible: true
            },
            {
                type: 'success',
                background: '#7dd3e8',
                duration: 2000,
                dismissible: true
            }
        ]
    });
    const formatCreatedAt = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString();
    };
    const submitCreate = () => {
        if (brand == '') {
            notyf.open({
                type: 'error',
                message: 'brand name is required'
            });
        } else {
            axios.post(api + 'brands', {
                name: brand,
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    Accept: "application/json",
                },
            }).then((res) => {
                if (res.data.check == false) {
                    if (res.data.msg) {
                        notyf.open({
                            type: 'error',
                            message: res.data.msg
                        });
                    }
                } else if (res.data.check == true) {
                    notyf.open({
                        type: 'success',
                        message: 'Create successfully'
                    });
                    if (res.data.data) {
                        setbrands(res.data.data);
                        resetCreate()
                    } else {
                        setRoles([]);
                    }
                }
            })
        }
    }
    function switchCate(params, value) {
        axios.put(api + 'brands/' + params.id, { status: value }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                Accept: "application/json",
            },
        }).then((res) => {
            if (res.data.check == false) {
                if (res.data.msg) {
                    notyf.open({
                        type: 'error',
                        message: res.data.msg
                    });
                }
            } else if (res.data.check == true) {
                notyf.open({
                    type: 'success',
                    message: 'Switch successfully'
                });
                console.log(res.data.data);
                setbrands(res.data.data);
            }
        })
    }

    const columns = [
        { field: "id", headerName: "#", width: 100, renderCell: (params) => params.rowIndex },
        { field: 'name', headerName: "Role name", width: 200, editable: true },
        {
            field: 'status',
            headerName: "Status",
            width: 70,
            renderCell: (params) => (
                <Switch
                    checked={params.value == 1}
                    onChange={(e) => switchCate(params, e.target.checked ? 1 : 0)}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            )
        },
        {
            field: 'created_at', headerName: 'Created at', width: 200, valueGetter: (params) => formatCreatedAt(params)
        }
    ];
    const handleCellEditStop = (id, field, value) => {
        axios
            .put(
                `${api}brands/${id}`,
                {

                    name: value,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                        Accept: "application/json",
                    },
                }
            )
            .then((res) => {
                if (res.data.check == true) {
                    notyf.open({
                        type: "success",
                        message: "brand is updated successfully",
                    });
                    setbrands(res.data.data);
                } else if (res.data.check == false) {
                    notyf.open({
                        type: "error",
                        message: res.data.msg,
                    });
                }
            });
    };

    useEffect(() => {
        axios.get(api + 'brands',{
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                Accept: "application/json",
            },
        }).then((res) => {
            setbrands(res.data);
        })
    }, [])
    return (
        <Layout>
            <>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Brand Modal</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <input type="text" value={brand} placeholder={brand == '' ? 'Place new brand' : ''} onChange={(e) => setbrand(e.target.value)} className="form-control" />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" size="sm" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" size="sm" onClick={() => submitCreate()}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>
                <div className="row mb-2">
                    <div className="col-md-2">
                        <button className='btn btn-sm btn-primary' onClick={handleShow}>Create</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-5">
                        {brands && brands.length > 0 && (
                            <Box sx={{ height: 400, width: '100%' }}>
                                <DataGrid
                                    rows={brands}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 5,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[5]}
                                    checkboxSelection
                                    disableRowSelectionOnClick
                                    onCellEditStop={(params, e) =>
                                        handleCellEditStop(params.row.id, params.field, e.target.value)
                                    }
                                />
                            </Box>
                        )}

                    </div>
                </div>
            </>
        </Layout>

    )
}

export default Brands