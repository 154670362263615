// eslint-disable
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Layout from '../components/Layout';
import { Notyf } from 'notyf';
import { Box, Switch, Typography } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import JoditEditor from "jodit-react";
import 'notyf/notyf.min.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function News() {
    const [create, setCreate] = useState(false);
    const [categories, setCategories] = useState([]);
    const [posts, setPosts] = useState([]);
    const [edit, setEdit] = useState('');
    const [idEdit, setIdEdit] = useState(0);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const api = process.env.REACT_APP_API_URL;
    const handleCellEditStop = (id, field, value) => {
        axios
            .put(
                `${api}posts/` + id, {
                [field]: value
            }
            ,{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    Accept: "application/json",
                },
            })
            .then((res) => {
                if (res.data.check == true) {
                    notyf.open({
                        type: "success",
                        message: "Post is updated successfully",
                    });
                    setPosts(res.data.data);
                } else if (res.data.check == false) {
                    notyf.open({
                        type: "error",
                        message: res.data.msg,
                    });
                }
            })
            .catch((error) => {
                console.error('Error updating Post:', error);
                notyf.open({
                    type: "error",
                    message: "An error occurred while updating the Post.",
                });
            });
    };
    const notyf = new Notyf({
        duration: 1000,
        position: {
            x: 'right',
            y: 'top',
        },
        types: [
            {
                type: 'warning',
                background: 'orange',
                icon: {
                    className: 'material-icons',
                    tagName: 'i',
                    text: 'warning'
                }
            },
            {
                type: 'error',
                background: 'indianred',
                duration: 2000,
                dismissible: true
            },
            {
                type: 'success',
                background: '#7dd3e8',
                duration: 2000,
                dismissible: true
            }
        ]
    });
    const formatCreatedAt = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString();
    };

    const handleCategoryChange = (id, value) => {
        axios.put(api + 'posts/' + id, { idCate: value },{
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                Accept: "application/json",
            },
        }).then((res) => {
            if (res.data.check == false) {
                if (res.data.msg) {
                    notyf.open({
                        type: 'error',
                        message: res.data.msg
                    });
                }
            } else if (res.data.check == true) {
                notyf.open({
                    type: 'success',
                    message: 'Switch successfully'
                });
                console.log(res.data.data);
                setPosts(res.data.data);
            }
        })
    }

    function switchPost(params, value) {
        axios.put(api + 'posts/' + params.id, { status: value },{
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                Accept: "application/json",
            },
        }).then((res) => {
            if (res.data.check == false) {
                if (res.data.msg) {
                    notyf.open({
                        type: 'error',
                        message: res.data.msg
                    });
                }
            } else if (res.data.check == true) {
                notyf.open({
                    type: 'success',
                    message: 'Switch successfully'
                });
                console.log(res.data.data);
                setPosts(res.data.data);
            }
        })
    }
    const columns = [
        { field: "id", headerName: "#", width: 100 },
        { field: 'title', headerName: "Title", width: 200, editable: true },
        { field: 'slug', headerName: "Slug", width: 200, editable: false },
        { field: 'summary', headerName: "Summary", width: 100, editable: true },
        {
            field: 'idCate',
            headerName: "Category",
            width: 200,
            renderCell: (params) => {
                return (
                    <select
                        value={params.value}
                        onChange={(e) => handleCategoryChange(params.row.id, e.target.value)}
                        className="form-control mt-2"
                    >
                        <option value="">Select Category</option>
                        {categories.map((category) => (
                            <option key={category.id} value={category.id}>{category.name}</option>
                        ))}
                    </select>
                );
            }
        },
        {
            field: 'status',
            headerName: "Status",
            width: 70,
            renderCell: (params) => (
                <Switch
                    checked={params.value == 1}
                    onChange={(e) => switchPost(params, e.target.checked ? 1 : 0)}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            )
        },
        {
            field: 'created_at', headerName: 'Created at', width: 200, valueGetter: (params) => formatCreatedAt(params)
        },
        {
            headerName: "Edit",
            width: 70,
            renderCell: (params) => (
                <Button
                    className='btn btn-sm btn-warning'
                    onClick={() => {
                        setEdit(params.row.content);
                        setIdEdit(params.row.id);
                        setShow(true);
                    }}
                >
                    Edit
                </Button>
            )
        },

    ];
    const [title, setTitle] = useState('');
    const [summary, setSummary] = useState('');
    const [idCate, setIdCate] = useState(0);
    const [content, setContent] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [filePreviews, setFilePreviews] = useState([]);
    const ChangeCreate = () => {
        if (create == false) {
            setCreate(true);
        } else {
            setCreate(false);

        }
    }

    const resetCreate = () => {
        setTitle('');
        setSummary('');
        setIdCate(0);
        setContent('');
        setSelectedFiles([]);
        setFilePreviews([]);
        setCreate(false);
    }

    const SubmitPost = () => {
        if (title == '') {
            notyf.open({
                type: "error",
                message: "Post name is required",
            });
        } else if (summary == '') {
            notyf.open({
                type: "error",
                message: "Summary is required",
            });
        }
        else if (idCate == 0) {
            notyf.open({
                type: "error",
                message: "Post category is required",
            });
        } else if (content == '') {
            notyf.open({
                type: "error",
                message: "Post content is required",
            });
        } else if (selectedFiles.length == 0) {
            notyf.open({
                type: "error",
                message: "Post Image is required",
            });
        } else {
            var formData = new FormData();
            formData.append('title', title);
            formData.append('summary', summary);
            formData.append('idCate', idCate);
            formData.append('content', content);
            formData.append('file', selectedFiles[0]);

        }
        axios.post(api + 'posts', formData,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                Accept: "application/json",
            },
        }).then((res) => {
            if (res.data.check == true) {
                setPosts(res.data.data);
                resetCreate();
            } else if (res.data.check == false) {
                if (res.data.msg) {
                    notyf.open({
                        type: "error",
                        message: res.data.msg,
                    });
                }
            }

        })

    }

    const handleRemoveImage = (index) => {
        const updatedFiles = [...selectedFiles];
        updatedFiles.splice(index, 1);
        setSelectedFiles(updatedFiles);

        const updatedPreviews = [...filePreviews];
        updatedPreviews.splice(index, 1);
        setFilePreviews(updatedPreviews);
    };

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        setSelectedFiles(files);

        const previews = files.map(file => URL.createObjectURL(file));
        setFilePreviews(previews);
    };

    const SubmitEdit = (e) => {
        if (edit == '') {
            notyf.open({
                type: 'error',
                message: 'Content is required'
            });
        } else {
            axios.put(api + 'posts/' + idEdit, { content: edit }).then((res) => {
                if (res.data.check == false) {
                    if (res.data.msg) {
                        notyf.open({
                            type: 'error',
                            message: res.data.msg
                        });
                    }
                } else if (res.data.check == true) {
                    notyf.open({
                        type: 'success',
                        message: 'Edit successfully'
                    });
                    console.log(res.data.data);
                    setPosts(res.data.data);
                }
            })
        }
    }
    const [file, setFile] = useState(null);
    const uploadFile = () => {
        if (file != null) {
            var formData = new FormData();
            formData.append('file', file);
            axios.post(api + 'posts/import', formData,{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    Accept: "application/json",
                },
            }).then((res) => {
                if (res.data.check == true) {
                    notyf.open({
                        type: "success",
                        message: "Upload successfully",
                    });
                    window.location.reload();   
                } else if (res.data.check == false) {
                }
            })
        }else{
            notyf.open({
                type: "error",
                message: "Please give me file",
            });
        }
    }
    const config = {
        height: '800px',
    }
    useEffect(() => {
        axios.get(api + 'news-categories',{
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                Accept: "application/json",
            },
        }).then((res) => {
            setCategories(res.data);
        });
        axios.get(api + 'posts',{
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                Accept: "application/json",
            },
        }).then((res) => {
            setPosts(res.data);
        });

    }, []);
    return (
        <>
            <Layout>

                <Modal
                    show={show}
                    onHide={handleClose}
                    fullscreen={true}
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Content</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <JoditEditor
                            value={edit}
                            tabIndex={1}
                            config={config}
                            onBlur={(newContent) => setEdit(newContent)}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={(e) => SubmitEdit()}>Submit</Button>
                    </Modal.Footer>
                </Modal>
                {/* ========================================= */}
                <div className="row">
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-2">
                                <button className='btn btn-sm btn-primary mb-3' onClick={(e) => ChangeCreate()}>Create</button>
                            </div>
                            <div className="col-md">

                            </div>
                            <div className="col-md-2">
                                {create == true && (
                                    <button className='btn btn-sm btn-primary' onClick={(e) => SubmitPost()}>Store</button>
                                )}
                            </div>
                        </div>
                        {create == true && (
                            <>

                                <div className="row">
                                    <div className="col-md-3">
                                        <label>Title:</label>
                                        <input type="text" className="form-control" onChange={(e) => setTitle(e.target.value)} />
                                    </div>
                                    <div className="col-md-3">
                                        <label>Summary:</label>
                                        <input type="text" className="form-control" onChange={(e) => setSummary(e.target.value)} />
                                    </div>
                                    <div className="col-md-3">
                                        <label>Category:</label>
                                        <select name="categoryId" className="form-control" defaultValue={0} value={idCate} onChange={(e) => setIdCate(e.target.value)}>
                                            <option value="0" disabled>Choose a category</option>
                                            {categories.map((category) => (
                                                <option key={category.id} value={category.id}>{category.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="row mb-2 mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="">Image</label>
                                        <input type="file" accept="image/*" onChange={handleImageChange} />
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input-group">
                                            <input
                                                type="file"
                                                className="form-control"
                                                id="inputGroupFile04"
                                                aria-describedby="inputGroupFileAddon04"
                                                aria-label="Upload"
                                                onChange={(e) => {
                                                    const file = e.target.files[0];
                                                    setFile(file);
                                                }}
                                            />
                                            <button
                                                className="btn btn-outline-secondary"
                                                type="button"
                                                id="inputGroupFileAddon04"
                                                onClick={(e) => uploadFile()}
                                            >
                                                Upload
                                            </button>
                                        </div>
                                    </div>

                                </div>
                                <div className="row mt-3">
                                    <JoditEditor
                                        value={content}
                                        tabIndex={1}
                                        onBlur={(newContent) => setContent(newContent)}
                                        onChange={(newContent) => setContent(newContent)}
                                    />
                                </div>

                                <div className="row mt-3">
                                    <div className="col-md" style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))', gap: '10px' }}>
                                        {filePreviews.map((image, index) => (
                                            <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10px' }}>
                                                <img src={image} alt={`Preview ${index}`} style={{ width: '100px', height: '100px', marginBottom: '5px' }} />
                                                <button className="btn btn-danger btn-sm w-100" onClick={() => handleRemoveImage(index)}>Remove</button>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </>
                        )}
                        {create == false && posts && posts.length > 0 && (
                            <Box sx={{ height: 400, width: '100%' }}>
                                <DataGrid
                                    rows={posts}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 5,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[5]}
                                    checkboxSelection
                                    disableRowSelectionOnClick
                                    onCellEditStop={(params, e) =>
                                        handleCellEditStop(params.row.id, params.field, e.target.value)
                                    }
                                />
                            </Box>
                        )}
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default News