import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout'
import axios from 'axios';
import { Notyf } from 'notyf';
import { Dropzone, FileMosaic } from "@dropzone-ui/react";
import { Autocomplete, TextField } from '@mui/material';
function Media() {
    const api = process.env.REACT_APP_API_URL;
    const [files, setFiles] = React.useState([]);
    const [products, setProducts] = useState([]);
    const [idProduct,setIdProduct] =useState(0);
    const [idPost,setIdPost] =useState(0);
    const [posts, setPosts] = useState([]);
    const updateFiles = (incommingFiles) => {

        setFiles(incommingFiles);
    };
    const notyf = new Notyf({
        duration: 1000,
        position: {
            x: 'right',
            y: 'top',
        },
        types: [
            {
                type: 'warning',
                background: 'orange',
                icon: {
                    className: 'material-icons',
                    tagName: 'i',
                    text: 'warning'
                }
            },
            {
                type: 'error',
                background: 'indianred',
                duration: 2000,
                dismissible: true
            },
            {
                type: 'success',
                background: '#7dd3e8',
                color: 'blue',
                duration: 2000,
                dismissible: true
            }
        ]
    });
    const uploadImage = () => {
        var formData = new FormData();
        files.forEach(file => {
            formData.append('files[]', file.file);
        });
        if(idProduct==0 &&idPost!=0){
            axios.post(api + 'gallery/post/'+idPost, formData,{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    Accept: "application/json",
                },
            })
            .then((res) => {
                if (res.data.check == true) {
                    notyf.open({
                        type: "success",
                        message: "Upload image successfully",
                    });
                    window.location.reload();
                } else if (res.data.check == false) {
                    if (res.data.msg) {
                        notyf.open({
                            type: "error",
                            message: res.data.msg,
                        });

                    }
                }
            })
            .catch((error) => {

            });
        }else if(idProduct!=0 &&idPost==0){
                axios.post(api + 'gallery/product/'+idProduct, formData,{
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    if (res.data.check == true) {
                        notyf.open({
                            type: "success",
                            message: "Upload image successfully",
                        });
                        window.location.reload();
                    } else if (res.data.check == false) {
                        if (res.data.msg) {
                            notyf.open({
                                type: "error",
                                message: res.data.msg,
                            });
    
                        }
                    }
                })
                .catch((error) => {
    
                });
            }        
    }
    useEffect(() => {
        axios.get(api + 'products',{
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                Accept: "application/json",
            },
        }).then((res) => {
           
            setProducts(res.data)
        })
        axios.get(api + 'posts',{
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                Accept: "application/json",
            },
        }).then((res) => {
            setPosts(res.data);
        })
    }, [])
    return (
        <Layout>
            <>
                <div className="row">
                    <div className="col-md-4">
                        <button className='btn btn-sm btn-primary mb-2' onClick={(e) => uploadImage()}>Upload</button>
                        <Dropzone onChange={updateFiles} accept="image/*" value={files}>
                            {files.map((file) => (
                                <FileMosaic {...file} preview />
                            ))}
                        </Dropzone>
                    </div>
                    <div className="col-md pt-5">
                        <div className="row">
                        <Autocomplete
                            disabled={idPost!==0?true:false}
                            disablePortal
                            id="combo-box-demo"
                            options={products} 
                            sx={{ width: 300 }}
                            onChange={(e, value) =>{
                                if (value) {
                                    setIdProduct(value.id)
                                }else{
                                    setIdProduct(0)
                                }
                            }} 
                            getOptionLabel={(product) => product.name} 
                            getOptionValue={(product) => product.id} 
                            renderInput={(params) => <TextField {...params} label="Products" />}
                        />
                        </div>
                        <div className="row mt-2">
                        <Autocomplete
                            disabled={idProduct!==0?true:false}
                            disablePortal
                            id="combo-box-demo"
                            options={posts} 
                            sx={{ width: 300 }}
                            onChange={(e, value) =>{
                                if (value) {
                                    setIdPost(value.id)
                                }else{
                                    setIdPost(0)
                                }
                            }} 
                            getOptionLabel={(post) => post.title} 
                            getOptionValue={(post) => post.id} 
                            renderInput={(params) => <TextField {...params} label="Post" />}
                        />
                        </div>
                    </div>
                </div>
            </>
        </Layout>

    )
}

export default Media