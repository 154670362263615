import  Home  from './pages/Home';
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { BrowserRouter,Routes ,Route} from 'react-router-dom';
import Role from './pages/Role';
import Users from './pages/Users';
import Permissions from './pages/Permissions';
import RolePermissions from './pages/RolePermissions';
import Categories from './pages/Categories';
import Brands from './pages/Brands';
import Products from './pages/Products';
import EditProduct from './pages/EditProduct';
import NewsCategories from './pages/NewsCategories';
import News from './pages/News';
import Media from './pages/Media';
import Login from './pages/Login';
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
        {!localStorage.getItem('token') ?
          <>
          <Route path='/' element={<Login/>}/>
          </>
           :
          <>
          <Route path='/' element={<Home/>}/>
          <Route path='/roles' element={<Role/>}/>
          <Route path='/permissions' element={<Permissions/>}/>
          <Route path='/categories' element={<Categories/>}/>
          <Route path='/products' element={<Products/>}/>
          <Route path='/news' element={<News/>}/>
          <Route path='/product/:id' element={<EditProduct/>}/>
          <Route path='/roles-has-permissions' element={<RolePermissions/>}/>
          <Route path='/brands' element={<Brands/>}/>
          <Route path='/news-categories' element={<NewsCategories/>}/>
          <Route path='/users' element={<Users/>}/>
          <Route path='/media' element={<Media/>}/>
          </>
          }
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
