/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Sidebar, Menu, MenuItem, useProSidebar, SubMenu } from "react-pro-sidebar";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import SpeedIcon from '@mui/icons-material/Speed';
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import EngineeringIcon from '@mui/icons-material/Engineering';
import Navbar from '../components/Navbar';
import LogoutIcon from "@mui/icons-material/Logout";
import { Link } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import { useAuth0 } from "@auth0/auth0-react";
function Layout({ children }) {
  const { collapseSidebar } = useProSidebar();
  const { logout } = useAuth0();
  const [permissions,setPermissions]= useState([]);
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('permissions');
    logout({ returnTo: window.location.origin });
    window.location.reload();
  };
  useEffect(()=>{
    const permission = localStorage.getItem('permissions');
    setPermissions(jwtDecode(permission));
    console.log(permissions);
  },[])
  return (
    <>
      <div className='row w-100'>
        <Navbar />
      </div>
      <div style={({ height: "90vh" }, { display: "flex" })}>
        <Sidebar style={{ minHeight: "90vh" }}>
          <Menu>
            <MenuItem
              icon={<MenuOutlinedIcon />}
              onClick={() => {
                collapseSidebar();
              }}
              style={{ textAlign: "center" }}
            >
              {" "}
              <h2>Admin</h2>
            </MenuItem>

            <MenuItem icon={<HomeOutlinedIcon />}>Home</MenuItem>
            {permissions.includes('users_crud') &&(
              <SubMenu icon={<EngineeringIcon />} label="Users">
              <Link  to={'/roles'}><MenuItem icon={<EngineeringIcon />}>User Roles</MenuItem></Link>
              <Link to={'/permissions'}><MenuItem icon={<EngineeringIcon />}>Permissions</MenuItem></Link>
              <Link to={'/users'}><MenuItem icon={<EngineeringIcon />}>Users</MenuItem></Link>
              <Link to={'/roles-has-permissions'}><MenuItem icon={<EngineeringIcon />}>Roles has permissions</MenuItem></Link>
            </SubMenu>
            )}
            {permissions.includes('products_crud') &&(
              <SubMenu icon={<EngineeringIcon />} label="Products">
              <Link to={'/categories'}><MenuItem icon={<EngineeringIcon />}>Categories</MenuItem></Link>
              <Link to={'/brands'}><MenuItem icon={<EngineeringIcon />}>Brands</MenuItem></Link>
              <Link to={'/products'}><MenuItem icon={<EngineeringIcon />}>Products</MenuItem></Link>
              </SubMenu>
            
            )}
            {permissions.includes('post_crud')&&(
              <SubMenu icon={<EngineeringIcon />} label="News">
              <Link to={'/news-categories'}><MenuItem icon={<EngineeringIcon />}>Categories</MenuItem></Link>
              <Link to={'/news'}><MenuItem icon={<EngineeringIcon />}>News</MenuItem></Link>
            </SubMenu>
            )}
            
            <SubMenu icon={<EngineeringIcon />} label="Media">
              <Link to={'/media'}><MenuItem icon={<EngineeringIcon />}>Media</MenuItem></Link>
            </SubMenu>
            <MenuItem onClick={handleLogout} icon={<LogoutIcon />}>
              Logout
            </MenuItem>
          </Menu>
        </Sidebar>
        <main className='p-4 w-100'>
          {children}
        </main>
      </div>

    </>
  )
}

export default Layout