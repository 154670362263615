/* eslint-disable */
import React from 'react'
import Layout from '../components/Layout'

function Home() {
  return (
    <Layout>
      <>
        Home 1
      </>
    </Layout>

  )
}

export default Home