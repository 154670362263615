/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Layout from '../components/Layout'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Box, Typography } from "@mui/material";
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
function Role() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [roles, setRoles] = useState([]);
    const [role, setRole] = useState('');
    const handleShow = () => setShow(true);
    const api = process.env.REACT_APP_API_URL;
    const resetCreate = ()=>{
        setRole('');
        handleClose()
    }
    const notyf = new Notyf({
        duration: 1000,
        position: {
            x: 'right',
            y: 'top',
        },
        types: [
            {
                type: 'warning',
                background: 'orange',
                icon: {
                    className: 'material-icons',
                    tagName: 'i',
                    text: 'warning'
                }
            },
            {
                type: 'error',
                background: 'indianred',
                duration: 2000,
                dismissible: true
            },
            {
                type: 'success',
                background: '#7dd3e8',
                duration: 2000,
                dismissible: true
            }
        ]
    });
    const formatCreatedAt = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString(); 
    };
    const submitCreate = () => {
        if (role == '') {
            notyf.open({
                type: 'error',
                message: 'Role name is required'
            });
        } else {
            axios.post(api + 'roles', {
                name: role,
            },{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    Accept: "application/json",
                },
            }).then((res) => {
                if (res.data.check == false) {
                    if (res.data.msg) {
                        notyf.open({
                            type: 'error',
                            message: res.data.msg
                        });
                    }
                } else if (res.data.check == true) {
                    notyf.open({
                        type: 'success',
                        message: 'Create successfully'
                    });
                    if (res.data.data) {
                        setRoles(res.data.data);
                        resetCreate()
                    } else {
                        setRoles([]);
                    }
                }
            })
        }
    }
    const columns = [
        { field: "id", headerName: "#", width: 100, renderCell: (params) => params.rowIndex },
        { field: 'name',headerName: "Role name", width: 200, editable:true },
        {
            field: 'created_at', headerName: 'Created at',width: 200,valueGetter: (params) => formatCreatedAt(params)
        }
    ];
    const handleCellEditStop = (id, field, value) => {
        axios
          .put(
            `${api}roles/${id}`,
            {
              name: value,
            },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    Accept: "application/json",
                },
            }
          )
          .then((res) => {
            if (res.data.check == true) {
              notyf.open({
                type: "success",
                message: "Role is updated successfully",
              });
              setRoles(res.data.data);
            } else if (res.data.check == false) {
              notyf.open({
                type: "error",
                message: res.data.msg,
              });
            }
          });
      };

    useEffect(() => {
        axios.get(api + 'roles',{
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                Accept: "application/json",
            },
        }).then((res) => {
            setRoles(res.data);
        })
        // console.log(process.env.REACT_APP_API_URL);
    }, [])
    return (
        <Layout>
            <>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Role Modal</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <input type="text" value={role} placeholder={role == '' ? 'Place new Role' : ''} onChange={(e) => setRole(e.target.value)} className="form-control" />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" size="sm" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" size="sm" onClick={() => submitCreate()}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>
                <div className="row mb-2">
                    <div className="col-md-2">
                        <button className='btn btn-sm btn-primary' onClick={handleShow}>Create</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-5">
                        {roles && roles.length > 0 && (
                            <Box sx={{ height: 400, width: '100%' }}>
                                <DataGrid
                                    rows={roles}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 5,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[5]}
                                    checkboxSelection
                                    disableRowSelectionOnClick
                                    onCellEditStop={(params, e) =>
                                        handleCellEditStop(params.row.id, params.field, e.target.value)
                                      }
                                />
                            </Box>
                        )}

                    </div>
                </div>
            </>
        </Layout>

    )
}

export default Role