/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Notyf } from "notyf";

function SignInSide() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { loginWithRedirect } = useAuth0();
  const { user, isAuthenticated, isLoading } = useAuth0();
  const api = process.env.REACT_APP_API_URL;
  const defaultTheme = createTheme();
  const [executed, setExecuted] = useState(false);
  const notyf = new Notyf({
    duration: 1000,
    position: {
      x: "right",
      y: "top",
    },
    types: [
      {
        type: "warning",
        background: "orange",
        icon: {
          className: "material-icons",
          tagName: "i",
          text: "warning",
        },
      },
      {
        type: "error",
        background: "indianred",
        duration: 2000,
        dismissible: true,
      },
    ],
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (email === "") {
      notyf.error("Email is missing");
    } else if (password === "") {
      notyf.error("Password is missing");
    } else {
      fetch(api + "users/checklogin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          if (res.check === true) {
            localStorage.setItem("token", res.token);
            localStorage.setItem('permissions',res.permission)
            notyf.open({
              type: "success",
              message: "Login successfully",
            });
            window.location.replace("/products");
          } else {
            notyf.open({
              type: "error",
              message: "Login fail",
            });
          }
        })
        .catch((error) => {});
    }
  };
  const fetchUser =()=>{
    fetch(api + "users/checklogin2", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: user.email
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        if (res.check === true) {
          localStorage.setItem("token", res.token);
          localStorage.setItem("permissions", res.permission);
          notyf.open({
            type: "success",
            message: "Login successfully",
          });
          window.location.replace("/products");
        } else {
          notyf.open({
            type: "error",
            message: "Login fail",
          });
        }
      })
      .catch((error) => {});
  }
  useEffect(() => {
    if (!executed && !isLoading && isAuthenticated && user) {
      fetchUser();
      setExecuted(true);
    }
  }, [executed, isLoading, isAuthenticated, user]);
  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage:
              "url(https://source.unsplash.com/random?wallpapers)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <br />
              <div className="row">
                <div className="col-md text-center">
                  <a
                    className="btn btn-warning mt-4"
                    onClick={() => loginWithRedirect()}
                    href="/"
                  >
                    <i class="bi bi-google"></i>
                  </a>
                </div>
              </div>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="/sign-up" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default SignInSide;