import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import { ProSidebarProvider } from "react-pro-sidebar";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider
  domain={process.env.REACT_APP_DOMAIN}
  clientId={process.env.REACT_APP_CLIENT_ID}
  authorizationParams={{
    redirect_uri: window.location.origin
  }}
>
<ProSidebarProvider>
  <App />
</ProSidebarProvider>
</Auth0Provider>
);
